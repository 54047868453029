import { Http, HttpAuth } from "../../config/Http"
import { changeLoading } from "./loading.action"
import { changeAlert } from "./alert.action"
import { changeNotify } from "./notify.action"

export const actionTypes = {
    INDEX : 'INDEX_REGISTER',
    CHANGE: 'CHANGE_REGISTER',
    ERROR: 'ERROR_REGISTER',
    SUCCESS: 'SUCCESS_REGISTER',
    CHECK: 'CHECK_REGISTER',
}

export const index = (payload) => ({
    type: actionTypes.INDEX,
    payload
})

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const setUserToken = (token) => dispatch => {
    localStorage.setItem('access_token', token);
}

export const register = (data) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Aguarde reservando Bilhetes...'
    }))

    return Http.post('/register', data)
        .then(res => {
            if(res.data.access_token){
                dispatch(setUserToken(res.data.access_token));
                dispatch(checkBilhetes(data));
            }
        })
        .catch(erro => {
            dispatch(changeLoading({ open: false }))
            if(erro.response) {
                dispatch(error(erro.response.data))
                if(erro.response.data.msg) {
                    dispatch(changeNotify({
                        open: true,
                        class: 'error',
                        msg: erro.response.data.msg
                    }))
                }
            }
        })
}

export const checkBilhetes = (data) => dispatch => {
    return HttpAuth.post('/check-bilhetes', data)
        .then(res => {
            if(res.data.await) {
                setTimeout(() => {
                    dispatch(checkBilhetes(data));
                }, 1500);
            }

            if(res.data.error) {
                dispatch(changeLoading({
                    open: false,
                }))
                dispatch(changeAlert({
                    open: true,
                    class: 'error',
                    msg: 'Os número(s) '+res.data.error+ ' acabaram de ser reservado(s) por outra pessoa'
                }))
            }
            if(!res.data.await && !res.data.error) {
                dispatch(changeLoading({
                    open: false,
                }))
                dispatch(index({steps: 2}))
            }
        })
}