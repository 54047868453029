import { actionTypes } from '../actions/rifas.action';
const initialState = {
    ativas: {
        data: []
    },
    finalizadas: {
        data: []
    }
}

const rifasReducer = (state = initialState, { type, payload, isLoadMore }) => {
    switch (type) {

    case actionTypes.ATIVAS:
        if(isLoadMore) {
            payload.ativas.data = state.ativas.data.concat(payload.ativas.data)
        }

        return { ...state, ...payload }

    case actionTypes.FINALIZADAS:
        if(isLoadMore) {
            payload.finalizadas.data = state.finalizadas.data.concat(payload.finalizadas.data)
        }

        return { ...state, ...payload }

    default:
        return state
    }
}

export default rifasReducer