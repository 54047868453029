import { HttpAuth } from '../../config/Http'

export const actionTypes = {
    INDEX: 'TRANSACTIONS_INDEX',
    SHOW: 'TRANSACTIONS_SHOW'
}

export const indexResponse = (payload, isLoadMore) => ({
    type: actionTypes.INDEX,
    payload,
    isLoadMore
})

export const index = (query, isLoadMore) => dispatch => {
    return HttpAuth.get('/transactions?'+ new URLSearchParams(query))
        .then(res => res && dispatch(indexResponse(res.data, isLoadMore)))
}

// SHOW

export const show = (id) => dispacth => {
    return HttpAuth.get('/transactions/'+ id)
        .then(res => res && dispacth(indexResponse(res.data)))
}