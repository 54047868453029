import { actionTypes } from '../actions/api.action'

const initialState = {
    app: {},
    sliders: [],
    perguntas: []
}

const apiReducer = (state = initialState, { type, payload }) => {
    switch (type) {

    case actionTypes.INDEX:
        return { ...state, ...payload }

    default:
        return state
    }
}

export default apiReducer
