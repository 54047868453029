import { HttpAuth } from "../../config/Http";
import { changeLoading } from "./loading.action";
import { changeNotify } from "./notify.action";

export const actionTypes = {
    ADD: 'ADD_CART',
    REMOVE: 'REMOVE_CART',
    INDEX: 'INDEX_CART'
};

export const addCart = (payload) => ({
    type: actionTypes.ADD,
    payload
})

export const removeCart = (payload) => ({
    type: actionTypes.REMOVE,
    payload
})

export const indexResponse = (payload) => ({
    type: actionTypes.INDEX,
    payload
})

export const index = () => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Aguarde buscando Bilhetes reservados...'
    }))

    return HttpAuth.get('get-reservas')
    .then(res => {
        dispatch(changeLoading({ open: false }))
        dispatch(indexResponse(res.data))
    })
    .catch(error => {
        dispatch(changeLoading({
            open: false,
        }))
        dispatch(changeNotify({
            open: true,
            class: 'error',
            msg: error.response.data.msg
        }))
    })
}

export const checkoutPro = (data) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Processando...'
    }))

    return HttpAuth.post('checkout', data)
        .then(res => {
            dispatch(changeLoading({
                open: false,
            }))

            if(res.data.id) {
                return res.data;
            }
        })
}

export const paypal = (data) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Processando...'
    }))

    return HttpAuth.post('paypal', data)
        .then(res => {
            dispatch(changeLoading({ open: false}))
            return res.data.orderID
        })
}

export const payStripe = (data) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Processando...'
    }))

    return HttpAuth.post('stripe', data)
        .then(res => {
            dispatch(changeLoading({ open: false}))
            return res.data
        })
}