import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { index } from './store/actions/api.action'
import { useDispatch, useSelector } from 'react-redux'

const Login = lazy(() => import('./view/auth/login'))
const Home = lazy(() => import('./view/home'))
const Bilhetes = lazy(() => import('./view/bilhetes'))
const MeusBilhetes = lazy(() => import('./view/meus-bilheres'))
const Checkout = lazy(() => import('./view/checkout'))
const Transactions = lazy(() => import('./view/transactions'))
const TransactionShow = lazy(() => import('./view/transactions/show'))

const Routes = () => {
    const dispatch = useDispatch()
    const app = useSelector(state => state.apiReducer.app)

    React.useEffect(() => {
        dispatch(index())
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {(app.id) ?
            <Router forceRefresh>
                <Suspense fallback={<div className="d-flex justify-content-center mt-5 pd-5"><CircularProgress/></div>}>
                    <Switch>
                        <Route exact path="/pay/checkout" component={Checkout}/>
                        <Route path="/bilhetes/:id/:afiliado?" component={Bilhetes}/>
                        <Route exact path="/meus-bilhetes" component={MeusBilhetes}/>
                        <Route path="/transactions/:id" component={TransactionShow}/>
                        <Route exact path="/transactions" component={Transactions}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/" component={Home}/>
                    </Switch>
                </Suspense>
            </Router>
            :
                <div className="d-flex justify-content-center align-items-center min-vh-100"><CircularProgress/></div>
            }
        </>
    )
}

export default Routes;