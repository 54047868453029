import { combineReducers } from 'redux'
import loadingReducer from './loading.reducer'
import notifyReducer from './notify.reducer'
import alertReducer from './alert.reducer'
import apiReducer from './api.reducer'
import rifasReducer from './rifas.reducer'
import bilhetesReducer from './bilhetes.reducer'
import cartReducer from './cart.reducer'
import registerReducer from './register.reducer'
import authReducer from './auth.reducer'
import transactionsReducer from './transactions.reducer'

const rootReducer = combineReducers({
    loadingReducer,
    notifyReducer,
    alertReducer,
    apiReducer,
    rifasReducer,
    bilhetesReducer,
    cartReducer,
    registerReducer,
    authReducer,
    transactionsReducer
})

export default rootReducer;