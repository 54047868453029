import { Http } from '../../config/Http';

export const actionTypes = {
    ATIVAS: 'RIFAS_ATIVAS',
    FINALIZADAS: 'RIFAS_FINALIZADAS',
};

export const ativasResponse = (payload, isLoadMore) => ({
    type: actionTypes.ATIVAS,
    payload,
    isLoadMore
})

export const rifasAtivas = (query, isLoadMore) => dispatch => {
    return Http.get('/rifas/ativas?'+ new URLSearchParams(query))
        .then(res => typeof res !== 'undefined' && dispatch(ativasResponse(res.data, isLoadMore)))
}

export const finalizadasResponse = (payload, isLoadMore) => ({
    type: actionTypes.FINALIZADAS,
    payload,
    isLoadMore
})

export const rifasFinalizadas = (query, isLoadMore) => dispatch => {
    return Http.get('/rifas/finalizadas?'+ new URLSearchParams(query))
        .then(res => typeof res !== 'undefined' && dispatch(finalizadasResponse(res.data, isLoadMore)))
}
