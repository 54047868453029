import { actionTypes } from '../actions/bilhetes.action';

const initialState = {
    rifa: {
        fotos: []
    },
    bilhetes: {
        data: []
    },
    list: {
        user: {},
        pagos: [],
        reservados: []
    },
    disponiveis: 0,
    reservados: 0,
    pagos: 0
}

const bilhetesReducer = (state = initialState, { type, payload, isLoadMore }) => {
    switch (type) {

    case actionTypes.INDEX:
        if(isLoadMore) {
            payload.bilhetes.data = state.bilhetes.data.concat(payload.bilhetes.data)
        }

        return { ...state, ...payload }

    case actionTypes.LIST:

        return { 
            ...state, 
            list: payload
        }

    default:
        return state
    }
}

export default bilhetesReducer