import { client_id, client_secret } from '../../config/App';
import { Http } from '../../config/Http';
import { changeLoading } from './loading.action';
import { changeNotify } from './notify.action';

export const actionTypes = {
    CHANGE : 'CHANGE_AUTH'
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const setUserToken = (token) => dispatch => {
    localStorage.setItem('access_token', token);
    dispatch(changeLoading({ open: false }))
    dispatch(change({
        email: '',
    }))
    window.location.replace('/meus-bilhetes')
}

export const login = (credentials) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Autenticando...'
    }))

    return Http.post('/oauth/token', {
        grant_type: 'password',
        client_id: client_id,
        client_secret: client_secret,
        username: credentials.email,
        password: credentials.password
    })
    .then(res => res.data.access_token && dispatch(setUserToken(res.data.access_token)))
    .catch(error => {
        if(error.response) {
            if(error.response.status === 401 || error.response.status === 400) {
                dispatch(changeNotify({
                    open: true,
                    class: 'error',
                    msg: 'Email não encontrado',
                }))
            }else {
                dispatch(changeNotify({
                    open: true,
                    class: 'error',
                    msg: 'Erro ao autenticar usuário',
                }))
            }   
        }
        dispatch(changeLoading({
            open: false
        }))
    })
}