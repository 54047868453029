export const baseUrl = 'https://api.motofilmadoresuk.com/'
export const apiUrl = 'https://api.motofilmadoresuk.com/api/'
export const afiliadoUrl = 'https://api.motofilmadoresuk.com/afiliado/'
export const siteAfiliado = 'https://afiliado.motofilmadoresuk.com'
export const SCROLL = 200
export const client_id = 2
export const client_secret = 'WAoRB9R0UYfmvCgKjcbY2R8NmftHwyjdBbfEYwDt'
export const client_afiliado_id = 4
export const client_afiliado_secret = '8NK8eBBxzUCuILkRTrlWEV7APB7aWi5DTaIm7a74'
export const paypal_id = 'AXIrrVKSqPVXpUPd8BGdLbfGpqGiXFGtjVWxRhZD9fqHrPaYp5ZjkIvgJtWsB_NszUAMJzrou5Se1saw'

export const stripe_key = 'pk_live_51JKhHcGVCLEQMf6SPyTGfXihVebZg1ZXP04ooEvkmhWtcjPx7iQ71iOWux3sVHgUHwcRlZ2eXB6a5fA7YZzaUNoZ001CerOXOh'

export const MP_PUBLIC_KEY = 'APP_USR-5bfea9c1-d5e7-423a-afd5-f1568ebe4325'