import Axios from 'axios';
import { baseUrl, apiUrl, afiliadoUrl } from './App'

export const Http = Axios.create({
    baseURL: baseUrl
})

export const HttpAuth = Axios.create({
    baseURL: apiUrl
})

export const HttpAfiliado = Axios.create({
    baseURL: afiliadoUrl
})

HttpAuth.interceptors.request.use(
    async (config) => {
        config.headers.authorization = 'Bearer ' + await localStorage.getItem('access_token')
        return config;
    }
)

HttpAfiliado.interceptors.request.use(
    async (config) => {
        config.headers.authorization = 'Bearer ' + await localStorage.getItem('access_token_afiliado')
        return config;
    }
)

HttpAuth.interceptors.response.use(response => {
    return response;
}, error => {
    if(error.response) {
        if(error.response.status === 401) {
            localStorage.removeItem('access_token');
            window.location.replace('/login')
        }
    }
})

HttpAfiliado.interceptors.response.use(response => {
    return response;
}, error => {
    if(error.response) {
        if(error.response.status === 401) {
            localStorage.removeItem('access_token_afiliado');
            window.location.replace('afiliado/login')
        }
    }
})