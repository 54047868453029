import { Http, HttpAuth } from '../../config/Http';
import { changeLoading } from './loading.action';

export const actionTypes = {
    INDEX: 'BILHETES_INDEX',
    LIST: 'BILHETES_LIST'
};

export const indexResponse = (payload, isLoadMore) => ({
    type: actionTypes.INDEX,
    payload,
    isLoadMore
})

export const index = (id, query, isLoadMore) => dispatch => {
    if(query.page > 1){
        dispatch(changeLoading({
            open: true,
            msg: 'Carregando mais bilhetes...'
        }))
    }

    return Http.post('/bilhetes/'+id, query)
        .then(res => {
            if(typeof res !== 'undefined') {
                dispatch(changeLoading({ open: false }))
                dispatch(indexResponse(res.data, isLoadMore))
            }
        })
}

export const listResponse = (payload) => ({
    type: actionTypes.LIST,
    payload
})

export const list = () => dispatch => {
    return HttpAuth.get('/meus-bilhetes')
        .then(res => res && dispatch(listResponse(res.data)))
}