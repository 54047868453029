import { actionTypes } from '../actions/auth.action'

const initialState = {
    credentials: {
        telefone: '',
        password: '123456',
    }
}

const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {

    case actionTypes.CHANGE:
        return { 
            ...state, 
            credentials: {
                ...state.credentials,
                ...payload
            }
        }

    default:
        return state
    }
}

export default authReducer