import { actionTypes } from '../actions/cart.action'

const initialState = {
    cart: [],
    reservas: {
        user: {},
        itens: []
    }
}

const cartReducer = (state = initialState, { type, payload }) => {
    switch (type) {

    case actionTypes.ADD:
        state.cart[payload] = payload

        return { ...state }

    case actionTypes.REMOVE:
        delete state.cart[payload];

        return { ...state }

    case actionTypes.INDEX:
        return { 
            ...state, 
            reservas: payload
        }

    default:
        return state
    }
}

export default cartReducer